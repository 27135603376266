import React from 'react'
import './style.scss'
import FormDetail from '../../../components/Content/ACF/Custom/FormDetail'
import { navigate } from "gatsby";

function PostTemplate(props) {

	const post = props.post
	const categoryHero = post.categories?.nodes[0]?.category_image?.categoryImage?.srcSet
  const postHero = post.post_image?.heroImage?.srcSet
	const image = post.post_image?.postImage?.srcSet
	const text = post.post_image?.field

	return (
		<>
		<div id="post-template">
  			<div className="img-container">
            <div className="img-wrapper"> 
                <img srcSet={ postHero ? postHero : categoryHero} alt=''/>
					      <h2 className="title">{post?.categories.nodes[0].name}</h2>
            </div>
        	</div>
			<div className={`content grid-12 ${text}`}>
				{image ?
				<div className="img-box">
					<div className="aspect-ratio">
						<img srcSet={image } alt=''/>		
					</div>
				</div>
				:
				null 
				}
				<div className="content-box" >
					<div className="box">
						{/* <h6 className="categories">
							{post.categories.nodes[0].name}
						</h6> */}
						<h4> {post.title}</h4>
						<div className="post-copy" dangerouslySetInnerHTML={{__html: post?.content}}/>
						<button className='button' onClick={() => navigate(-1)}>
							Back
						</button>
					</div>
				</div>	
			</div>
		</div>
		
		<section id="section-consumer-request-information" className="content is-inview inview request-information grid-12">
			<div id="block-custom-request-information" className="block block-custom span-12 full-width">
				<FormDetail fields="REQUEST INFORMATION"/>
			</div>
		</section>
				
		</>
	)
}

export default PostTemplate
import React, {useContext} from 'react'
import { Router } from '@reach/router'
import ApolloContext from '../../context/ApolloContext'
import SEO from "../../components/seo"

import Degree from './Degree'
import PostTemplate from './PostTemplate'

function Archives(props) {
	const apollo = useContext(ApolloContext)

	const events = apollo.data?.events.nodes.filter(function(key) {
		return key.postId === parseInt(props.params['*'])
	})[0]

	const campusLife = apollo.data?.campusLife.nodes.filter(function(key) {
		return key.postId === parseInt(props.params['*'])
	})[0]

	const studentStories = apollo.data?.studentStories.nodes.filter(function(key) {
		return key.postId === parseInt(props.params['*'])
	})[0]

	const News = apollo.data?.News.nodes.filter(function(key) {
		return key.postId === parseInt(props.params['*'])
	})[0]

	const sonhs = apollo.data?.sonhs.nodes.filter(function(key) {
		return key.postId === parseInt(props.params['*'])
	})[0]

	const nursing = apollo.data?.nursing.nodes.filter(function(key) {
		return key.postId === parseInt(props.params['*'])
	})[0]

	const testimonials = apollo.data?.testimonials.nodes.filter(function(key) {
		return key.postId === parseInt(props.params['*'])
	})[0]

	const academics = apollo.data?.academics.nodes.filter(function(key) {
		return key.slug === props.params['*'].replace('academic/','')
	})[0]

	
	let BlogPage = () => <h1>This is for the empty stuff, experimenting</h1>
	let SinglePost = () => {
		
		if(News) 
			return <PostTemplate post={News} hero={apollo.data.NewsHero} />
			
		if(studentStories) 
			return <PostTemplate post={studentStories} />

		if(campusLife) 
			return <PostTemplate post={campusLife} />

		if(events) 
			return <PostTemplate post={events} />

		if(sonhs) 
			return <PostTemplate post={sonhs} />

		if(nursing) 
			return <PostTemplate post={nursing} />

		if(testimonials) 
			return <PostTemplate post={testimonials} />
	
		return null
	}

	let SinglePostAcademics = () => {

		if(academics) {
			return <>
				<SEO title={academics.title} />
				<Degree key={academics.id} {...academics} />
			</>
		}
		
		return null
	}

	return (
		<div>
			<Router>
				<BlogPage path="/archives/" />
				<SinglePost path="/archives/:id" />
				<SinglePostAcademics path="/archives/academic/:id" />
			</Router>
		</div>
	)
}

export default Archives
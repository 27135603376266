import React from "react"
import Archivez from '../apollo/archives'
import { graphql } from "gatsby"
import SEO from "../components/seo"

//import NaviContext from '../context/NaviContext'

import Footer from '../components/Footer'

function Archives(props) {
	//const naviContext = useContext(NaviContext)
  const footerImage = props.data?.wordpressAcfOptions.options.footer_image?.localFile.childImageSharp.fluid

	// Just rerouting to apollo/archives
	return (
		<>
			<div id="mainframe-cover" className="layer">
				<div id="mainframe-bg-overlay" className="layer"></div>
				<div id="mainframe-stripes" className="layer">
					
				</div>
			</div>

			<main id="mainframe" className={`c0 archives main-${props.pageContext.slug === "/" ? "frontpage" : props.pageContext.slug}`}>
        <SEO title={`News`} description="Description" />

				<Archivez {...props} />

        <Footer image={footerImage} />

			</main>
		</>
	)
}

export default Archives

export const query = graphql`
  query {
    wpgraphql {
      ...wpNaviPrimary
      ...wpNaviSecondary
    }
    wordpressAcfOptions {
      options {
        footer_image {
          localFile {
            ...imgStandard
          }
        }
      }
    }
  }
`